import Link from "../Link"
import React from "react"

export default function Footer() {
  return (
    <div className="bg-seventh text-fourth text-xs px-4 pt-4 pb-8 lg:p-8">
      <div className="max-w-4xl mx-auto grid md:grid-cols-3 gap-4 w-full  lg:px-8">
        <div className="">
          <div className="font-bold">Bodecker Partners AB</div>
          <div>
            is an independent partner-owned company active in the field of
            consultancy, analysis and management of energy price risks. We
            handle price risks within the fields of wind- and solar power, CHP,
            district heating, biofuel and gas.
          </div>
        </div>
        <div className="space-y-2">
          <div>
            <div className="font-bold">Contact Us</div>
            <div>
              Mail: info@bodeckerpartners.com <br />
              Tel: +46 732 55 87 27
            </div>
          </div>
          <div>
            <div className="font-bold">Adress</div>
            <div>
              Bodecker Partners
              <br />
              Lilla Varvsgatan 1b
              <br />
              211 74 Malmö{" "}
            </div>
          </div>
        </div>
        <div className="">
          <div className="font-bold">Social medias</div>
          <div>
            <Link to="https://www.linkedin.com/company/bodecker-partners/?originalSubdomain=se">
              LinkedIn
            </Link>{" "}
          </div>
        </div>
      </div>
    </div>
  )
}
