export const MenuEn = {
  name: "Main Menu",
  items: [
    {
      title: "Services",
      route: null,
      subMenu: [
        {
          subMenuGroup: {
            name: "For Asset Owners And Investors",
            children: [
              {
                title: "Revenue Management",
                route: "/en/services/revenue-management/",
              },
              { title: "PPA", route: "/en/services/ppa-2/" },
              { title: "Investment Advisory", route: "/en/services/advisory/" },
            ],
          },
        },
        {
          subMenuGroup: {
            name: "For Power Consumers",
            children: [
              {
                title: "100% Renewable Power",
                route: "/en/services/renewable-power/",
              },
              {
                title: "Corporate PPA",
                route: "/en/services/corporate-ppa-for-power-consumers/",
              },
            ],
          },
        },
        {
          subMenuGroup: {
            name: "For clients with exposure to EU-ETS",
            children: [
              {
                title: "Bodecker Carbon",
                route: "https://www.bodeckercarbon.com",
              },
            ],
          },
        },
      ],
    },
    {
      title: "Reports",
      route: "/en/reports/",
      subMenu: null,
    },
    {
      title: "News",
      route: "https://news.bodeckerpartners.com",
      subMenu: null,
    },
    {
      title: "About Us",
      route: "/en/about-us",
      subMenu: null,
    },
  ],
}
